import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { AppLogoWrapper } from './AppLogo.styles'
import logo from './logo.png'

class AppLogo extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }
    return <AppLogoWrapper id="logo" src={logo} spinning alt="logo" />
  }
}

export default connect(state => state)(AppLogo)
