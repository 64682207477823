import actions from '../actions/action-types';

interface UserAction {
  type: String;
  loginToken?: String;
  loginError?: String;
}

export const userReducer = (state: any = {}, action: UserAction) => {
  switch (action.type) {
    case actions.USER_LOGIN_START:
      return { ...state, isLoggingIn: true, loginError: null };
    case actions.USER_LOGIN_ERROR:
      const { loginError } = action;
      return { ...state, isLoggingIn: false, loginError };
    case actions.USER_LOGIN_SUCCESS:
      const { loginToken } = action;
      return {
        ...state,
        isLoggingIn: false,
        loginToken,
        loginError: null,
      };
    case actions.USER_LOGOUT:
      return { ...state, loginToken: null };
    default:
      break;
  }
  return state;
};
