import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  background-color: #003eb6;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
`;
