import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Link from '../Link'
import AppLogo from '../AppLogo'
import LoginButtons from '../LoginButtons'
import { HeaderWrapper } from './Header.styles'

class Header extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>
    }
    return (
      <HeaderWrapper>
        <Link to="/">
          <AppLogo />
        </Link>
        <div style={{ flexGrow: 1 }} />
        <LoginButtons />
      </HeaderWrapper>
    )
  }
}

Header.propTypes = {
  // bla: PropTypes.string,
}

Header.defaultProps = {
  // bla: 'test',
}

const mapStateToProps = state => ({
  // blabla: state.blabla,
})

const mapDispatchToProps = dispatch => ({
  // fnBlaBla: () => dispatch(action.name()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
