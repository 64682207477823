import styled, { css } from 'styled-components';

// TODO: Not working
const rotate360 = css`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const AppLogoWrapper = styled.img`
  height: 80px;
  ${({ spinning }) =>
    spinning && `animation: ${rotate360} infinite 120s linear;`}
`;
