import styled from 'styled-components'

export const StyledLoginForm = styled.form`
  background: #003eb6;
  max-width: 300px;
  margin: 1em auto;
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  * {
    box-sizing: border-box;
  }
  label {
    display: block;
    font-weight: bold;
    font-size: 18px;
    color: #fff;
  }
  input[type='email'],
  input[type='password'] {
    display: block;
    width: 100%;
    height: 40px;
    margin: 1em auto;
    background: #ffffff;
    border: 1px solid #b8b9ba;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    font-size: 18px;
  }

  input[type='submit'] {
    height: 40px;
    background: #0085ff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    color: #ffffff;
    border-radius: 4px;
    padding: 8px 40px;
    font-size: 18px;
    border: none;
  }

  .error {
    margin: 20px auto;
  }
`
