import actions from './action-types'
import axios from 'axios'

// TODO: Its OK to add this function as an extra parameter for redux-thunk
//  ie: applyMiddleware(thunk.withExtraArgument({ createAxiosInstance }))
const createAxiosInstance = (token?: String) =>
  axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? 'https://taskility.appspot.com' : 'http://localhost:3001',
    timeout: 5000,
    headers: token ? { Authorization: `Bearer ${token}` } : null,
  })

const userLoginStart = () => ({ type: actions.USER_LOGIN_START })
const userLoginSuccess = (loginToken: String) => ({
  type: actions.USER_LOGIN_SUCCESS,
  loginToken,
})
const userLoginError = (loginError: String) => ({
  type: actions.USER_LOGIN_ERROR,
  loginError,
})

const login = (email: String, password: String) => {
  return (dispatch: Function, getState: Function) => {
    const { isLoggingIn, loginToken } = getState()
    if (isLoggingIn || loginToken) {
      return
    }

    dispatch(userLoginStart())

    const axiosInstance = createAxiosInstance()
    console.log({ axiosInstance })

    axiosInstance
      .post('/app/users/login', { email, password })
      .then(({ data }) => {
        dispatch(userLoginSuccess(data.token))
      })
      .catch(e => dispatch(userLoginError(e.message)))
  }
}

const logout = () => {
  return (dispatch: Function) =>
    dispatch({
      type: actions.USER_LOGOUT,
    })
}

const sendDialogFlowTextUtteranceStart = (userMessage: String) => ({
  type: actions.SEND_DIALOG_FLOW_TEXT_UTTERANCE_START,
  userMessage,
})
const sendDialogFlowTextUtteranceSuccess = (agentMessage: String) => ({
  type: actions.SEND_DIALOG_FLOW_TEXT_UTTERANCE_SUCCESS,
  agentMessage,
})
const sendDialogFlowTextUtteranceError = (agentMessageError: String) => ({
  type: actions.SEND_DIALOG_FLOW_TEXT_UTTERANCE_ERROR,
  agentMessageError,
})

const sendDialogFlowTextUtterance = (text: String) => {
  return (dispatch: Function, getState: Function) => {
    const { loginToken } = getState()
    const axiosInstance = createAxiosInstance(loginToken)

    dispatch(sendDialogFlowTextUtteranceStart(text))
    axiosInstance
      .post('/app/aiagent/ask', { text })
      .then(({ data }) => {
        dispatch(sendDialogFlowTextUtteranceSuccess(data.response))
      })
      .catch(error => dispatch(sendDialogFlowTextUtteranceError(error.message)))
  }
}

export default {
  login,
  logout,
  sendDialogFlowTextUtterance,
}
