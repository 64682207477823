import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';
import AIAgentForm from './components/AIAgentForm';
import LoginForm from './components/LoginForm';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <PrivateRoute path="/" exact component={AIAgentForm} />
          <Route path="/login" component={LoginForm} />
        </div>
      </Router>
    );
  }
}

export default App;
