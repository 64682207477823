import styled from 'styled-components';

export const StyledAgent = styled.div`
  max-width: 400px;
  padding: 20px;
  input {
    width: 100%;
    border: none;
    background: #ddd;
    font-size: 16px;
    margin: 3px 0;
    padding: 3px 0;
    border-radius: 3px;
  }
  button {
    float: right;
    background: #0170ff;
    padding: 5px 10px;
    border: none;
    margin: 0;
    border-radius: 3px;
    color: #FFF;
    font-size: 1.2em;
  }
  button:disabled {
    background: #888;
  }
  ul {
    padding: 0;
  }
`;

export const StyledMessage = styled.li`
  list-style: none;
  padding: 5px;
  margin: 2px 0;
  text-align: ${({ from }) => {
    return from === 'agent' ? 'right' : 'left';
  }};
  background: ${({ from }) => {
    return from === 'agent' ? '#99f9de44' : '#0170ff44';
  }};
`;
