import React from 'react'
import { connect } from 'react-redux'
import actions from '../../state/actions'
import { StyledAgent, StyledMessage } from './AIAgent.styles'

const Message = ({ message }) => (
  <StyledMessage from={message.from}>
    <span>{message.text}</span>
  </StyledMessage>
)

const AIAgent = props => {
  const msgSubmit = event => {
    event.preventDefault()
    const message = document.getElementById('message').value
    if (message) {
      props.sendDialogFlowTextUtterance(message)
      document.getElementById('message').value = ''
    }
  }

  const { isWaitingAgentResponse, agentMessageError, agentMessages } = props
  return (
    <StyledAgent>
      <ul id="messages">
        {agentMessages && agentMessages.map((message, idx) => <Message key={idx} message={message} />)}
      </ul>
      <div>{agentMessageError}</div>
      <form onSubmit={msgSubmit}>
        <input id="message" autoComplete="off" />
        <button disabled={isWaitingAgentResponse}>{isWaitingAgentResponse ? 'Un momento...' : 'Enviar'}</button>
      </form>
    </StyledAgent>
  )
}

export default connect(state => state.aiAgent, actions)(AIAgent)
