import React from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import actions from '../../state/actions'
import { StyledLoginForm } from './LoginForm.styles'

const LoginForm = ({ loginToken, isLoggingIn, loginError, login, location }) => {
  const onSubmit = event => {
    event.preventDefault()
    const email = document.getElementById('email').value
    const password = document.getElementById('password').value
    login(email, password)
  }

  return !loginToken ? (
    <StyledLoginForm action="" onSubmit={onSubmit}>
      <label htmlFor="email">Correo electrónico</label>
      <input id="email" type="email" />
      <label htmlFor="password">Contraseña</label>
      <input id="password" type="password" />
      <input type="submit" value="Iniciar sesión" />
      {isLoggingIn && <div>Iniciando...</div>}
      {loginError && <div className="error">Error: {loginError}</div>}
    </StyledLoginForm>
  ) : (
    <Redirect to={{ pathname: '/', state: { from: location } }} />
  )
}

export default connect(state => state.user, actions)(LoginForm)
