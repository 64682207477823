import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';

export const loadState = () => {
  try {
    const state = localStorage.getItem('appState');
    if (state === null) {
      return undefined;
    }
    return JSON.parse(state);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    localStorage.setItem('appState', JSON.stringify(state));
  } catch (err) {}
};

const preloadedState = loadState();
let middleware = applyMiddleware(thunk);
const store: Store = createStore(
  reducer,
  preloadedState,
  process.env.NODE_ENV !== 'production'
    ? composeWithDevTools(middleware)
    : middleware
);

store.subscribe(() => {
  // TODO: Throttle and select data to store (dont store the full state) to reduce the cost of this operation.
  saveState(store.getState());
})

export default store;
