import React from 'react';
import { connect } from 'react-redux';
import Link from '../Link';
import actions from '../../state/actions';
import { StyledLoginButtons } from './LoginButtons.styles';

const LoginButtons = props => (
  <StyledLoginButtons>
    {props.loginToken && (
      <Link to="/" onClick={props.logout}>
        Salir
      </Link>
    )}
    {/* Disabled: not required because not logged user is always redirected to login page */}
    {/* {!props.loginToken && <Link to="/login">Iniciar sesión</Link>} */}
  </StyledLoginButtons>
);

export default connect(
  state => state.user,
  actions
)(LoginButtons);
